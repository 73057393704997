.loading-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;
}

.loading-text {
  height: 22.5vh
}

.loading-text-error {
  height: 22.5vh;
  color: red;
}

@keyframes blink {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
  }
}

.loading-logo {
  position: absolute;
  animation: blink 2s ease-in;
  animation-iteration-count: infinite;
  height: 20vh;
}
